export interface TsAppVersion {
    version: string;
    name: string;
    description?: string;
    versionLong?: string;
    versionDate: string;
    gitCommitHash?: string;
    gitCommitDate?: string;
    gitTag?: string;
};
export const versions: TsAppVersion = {
    version: '1.1.6',
    name: 'prepplus-portal',
    versionDate: '2024-08-16T23:43:33.957Z',
};
export default versions;
