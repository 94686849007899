// Configuration file for application-specific settings
import { ApplicationConfiguration } from '@core/models';
import versions from './_versions';

export const config: ApplicationConfiguration = {
  apiURL: 'https://api.qa.prepeval.mathematica-dev.org',
  appDescription: '',
  appOwner: 'Mathematica',
  appRoutes: {
    signIn: '/auth/signin',
    signOut: '/auth/signout',
  },
  appTitle: 'PREP',
  debugMode: true,
  staging: false,
  production: false,
  serviceWorker: false,
  timeoutLength: 14,
  version: versions.version,
  aws: {
    clientId: '7sr9lmqakcbn2unkfgbelclm70',
    region: 'us-east-1',
    userPoolId: 'us-east-1_pexk8Lydy',
  },
  siteUrl: 'https://prepeval.acf.hhs.gov',
};
